@use '~styles/common';
@use '~styles/fonts';

.translationSection {
  padding: 20px;

  .translationItem:not(:last-child) {
    margin-bottom: 24px;
  }

  .translationFormContainer {
    align-items: center;
    display: flex;
  }
}

.indexWrapper {
  margin-bottom: 24px;
  max-width: 200px;
}
