@use '~styles/fontSizes';
@use '~styles/colors';

.headerWrapper {
  font-weight: initial;

  > p {
    color: colors.$info-color;
    font-size: fontSizes.$font-regular;
    line-height: 21px;
    margin-top: 4px;
  }
}
