.logicActionForm {
  display: grid;
  grid-column-gap: 27px;
  grid-row-gap: 21px;
  grid-template-columns: 0.2fr 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  margin: 16px auto;

  .originCondition {
    grid-area: 1 / 1 / 2 / 2;
  }
  .questionText {
    grid-area: 1 / 2 / 2 / 4;
  }
  .conditionOperator {
    grid-area: 2 / 2 / 3 / 3;
  }
  .questionChoiceSelect {
    grid-area: 2 / 3 / 3 / 4;
  }
  .destinationText {
    grid-area: 3 / 1 / 4 / 2;
  }
  .actionOperator {
    grid-area: 3 / 2 / 4 / 3;
  }
  .destinationChoice {
    grid-area: 3 / 3 / 4 / 4;
  }
}
