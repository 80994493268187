/* stylelint-disable */
.demographicsSurveysWidgetGenerator {
  display: flex;
  gap: 24px;
  width: 100%;
  align-items: flex-start;

  tbody {
    td:nth-of-type(1),
    td:nth-of-type(3) {
      font-weight: 600;
    }
  }
}
