@use '~styles/colors';
@use '~styles/fontSizes';

.flagsIcon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  position: relative;
}

.informationFlag {
  align-items: center;
  display: flex;
  justify-content: space-between;
  i {
    margin-right: 12px;
  }

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}
