@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-colors';
.questionTextWrapper {
  min-width: 330px;
}
.questionText {
  @include zcd-typography.t-heading-5;
}

.required {
  color: zcd-colors.$zcd-red-90;
  display: inline-block;
  margin-inline-start: 4px;
}
