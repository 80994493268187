@use '~styles/colors';

.dropdownWrapper {
  position: relative;
}

.dropdown {
  appearance: none;
  background-color: colors.$blue-10-color;
  border: 1px solid colors.$light-gray-color;
  border-radius: 100px;
  color: colors.$raisin-black-color;
  font-size: 14px;
  height: 36px;
  padding-left: 16px;
  padding-right: 38px;
}

.wideArrowDownIcon {
  color: colors.$raisin-black-color;
  position: absolute;
  right: 16px;
  top: 15px;
}
