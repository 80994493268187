@use '~styles/colors';

.checkboxAsSwitchWrapper {
  align-items: center;
  column-gap: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  // stylelint-disable selector-no-qualifying-type
  > input[type='checkbox'] {
    appearance: none;
    opacity: 0;
    position: absolute;

    + span {
      background-color: colors.$blue-20-color;
      border-radius: 100px;
      display: block;
      height: 24px;
      position: relative;
      transition: background 0.25s;
      width: 46px;

      &::before {
        background-color: colors.$white-color;
        border-radius: inherit;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.113083);
        content: '';
        height: 22px;
        left: 1px;
        position: absolute;
        top: 1px;
        transition: all 0.25s;
        width: 22px;
      }
    }

    &:checked {
      + span {
        background-color: colors.$primary-color;

        &::before {
          left: 23px;
        }
      }
    }
  }
}
