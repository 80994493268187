@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-colors';

.otherScores {
  margin-block-start: 64px;
  .otherScore {
    margin-inline-end: 64px;
  }
  .scoreLabel {
    @include zcd-typography.t-small;

    color: zcd-colors.$zcd-gray-80;
  }
  .score {
    @include zcd-typography.t-heading-1;

    color: zcd-colors.$zcd-gray-80;
    margin-inline-end: 8px;
  }

  .negative {
    color: zcd-colors.$zcd-color-negative;
  }
}
