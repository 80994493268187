// stylelint-disable selector-no-qualifying-type
@use '~styles/colors';
@use '~styles/common';

.newQuestionWrapper {
  background-color: colors.$white-color;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 32px 24px 20px;
  row-gap: 20px;
}

.topMargin {
  margin-top: 24px;
}

.newQuestionGrid {
  column-gap: 24px;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.questionText {
  @extend %form-control;

  width: 100%;
}

.questionDescription {
  @extend %form-control;

  margin-top: 12px;
  min-height: 80px;
  resize: vertical;
  width: 100%;
}

.dropdown {
  @extend %squared-dropdown;

  width: 184px;
}

.newQuestionFooter {
  display: flex;
  place-content: end;
}

.verticalSeparator {
  border-left: 1px solid colors.$light-gray-color;
  margin: 0 24px;
}

.footerForNewQuestion {
  background-color: colors.$white-color;
  border-top: 1px solid colors.$color-gray-border;
  bottom: 0;
  display: flex;
  justify-content: end;
  padding: 24px;
  position: sticky;
}

.cancelButton {
  margin-right: 40px;
}

.removeButton {
  cursor: pointer;
}

.selectDropdown {
  width: 100%;

  .choiceOption {
    width: 400px;
  }
}

.marginAddText {
  margin-left: 12px;
}

.sectionLayout {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.optionWrapper {
  align-items: center;
  column-gap: 8px;
  display: flex;
  flex-direction: row;

  > svg[data-name='six-small-circles.svg'] {
    color: colors.$info-color;
    cursor: grab;
    transform: rotate(90deg);

    &.hidden {
      cursor: initial;
      opacity: 0;
    }
  }

  > input[type='checkbox'],
  > input[type='radio'] {
    height: 16px;
    margin: 0;
    width: 16px;
  }
}

:export {
  whiteColor: colors.$white-color;
}
