// stylelint-disable selector-no-qualifying-type
@use '~styles/colors';
@use '~styles/common';

.container {
  overflow: visible;
  width: 100vw;
}

.questionWrapper {
  background-color: colors.$white-color;
  border-left: 1px solid colors.$light-gray-color;
  border-right: 1px solid colors.$light-gray-color;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  // The mininum height is at least the height of the contextual toolbar,
  // to avoid any overflow scroll issues.
  min-height: common.$question-contextual-toolbar-height;
  position: relative;
  width: 688px;

  .rootLevelQuestionBorder {
    background-color: colors.$blueberry-color;
    height: 4px;
    width: 100%;
  }

  &.childQuestionWrapper {
    border-left: 0;
    border-right: 0;
    margin: 0;
    // A child question will fit inside of a parent QuestionWrapper, so the
    // width needs to be decreased from its normal width. The width is
    // subtracting 24px left + 24px right. 688px - 48px = 640px
    width: 640px;
  }

  > .draggableArea {
    align-items: center;
    cursor: grab;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 100%;

    > svg[data-name='six-small-circles.svg'] {
      color: colors.$info-color;
    }
  }

  > .content {
    margin: 16px 24px 32px;

    &.childQuestion {
      margin-left: 0;
      margin-right: 0;
    }

    &.groupQuestion {
      margin-bottom: 0;
    }

    &.viewOnly {
      padding-top: 20px;
    }

    .nonGroupQuestion {
      margin-left: 60px;
    }

    .questionImage {
      padding: 12px;
    }
  }
}
