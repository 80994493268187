@use '~styles/colors';

.actionsMenuWrapper {
  margin-left: 30px;
  position: relative;
}

.actionsMenu {
  background-color: colors.$white-color;
  border: 1px solid colors.$light-gray-color;
  border-radius: 8px;
  bottom: 0;
  display: flex;
  filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.1));
  flex-direction: column;
  padding: 8px;
  position: absolute;
  right: 35px;
  width: 200px;

  .actionMenuItem {
    column-gap: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 7.5px 0;
    place-items: center;

    &:hover {
      background-color: colors.$gray-20-color;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}
