.footer {
  display: flex;

  button {
    &:first-child {
      margin-right: 40px;
    }
  }
}

.deleteButton {
  margin-bottom: 16px;
}

.addButton {
  margin-top: 16px;
}
