@use '~styles/fontSizes';
@use '~styles/colors';

.publishButton {
  font-size: fontSizes.$font-large;
  font-weight: 400;
  margin-top: 16px;
  padding-left: 24px;
  padding-right: 24px;

  .marginPublishText {
    margin-left: 12px;
  }
}

:export {
  whiteColor: colors.$white-color;
}
