@use '@zencity/common-ui/lib/zcd-colors.scss';
@use '@zencity/common-ui/lib/zcd-typography.scss';

$big-padding: 30px;
// We must use selector-max-compound-selectors in order to override the ZCDTable styles.
/* stylelint-disable */
.tableOverrides {
  // Override the table column colors and padding.
  // These variables should be identical to the colors used in the BenchmarkComparisonScale module for their
  // respective shape colors.
  table tbody tr:first-child td {
    padding-block-start: 6px !important;
  }
  table tbody tr:last-child td {
    padding-block-end: 6px !important;
  }
  table tbody tr td {
    padding-block: 3px !important;
  }
  td:nth-child(1) {
    font-weight: 600;
  }
  td:nth-child(2) {
    color: zcd-colors.$zcd-gray-80;
    font-weight: 600;
  }
  td:nth-child(3) {
    font-weight: 600;
  }
  td:nth-child(4) {
    color: zcd-colors.$zcd-blue-60;
    font-weight: 600;
  }
  tfoot tr td {
    padding-block: 3px !important;
  }
  tfoot tr td:last-child {
    padding-inline: 3px !important;
  }

  &.biggerPadding {
    tbody tr td {
      padding-block: $big-padding !important;
      border-bottom: solid 1px zcd-colors.$zcd-gray-40 !important;
    }
    tbody tr:first-child td {
      padding-block-start: $big-padding !important;
    }
    tbody tr:last-child td {
      padding-block-end: $big-padding !important;
    }
  }
}

.scalePercentages {
  color: zcd-colors.$zcd-gray-80;
  @include zcd-typography.t-small;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  .scalePercentage {
    width: 31px;
    text-align: center;
  }
}
