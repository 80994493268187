@use '~styles/colors';
@use '~styles/common';

%form-boundaries {
  margin: 29px auto;
  width: 710px;
}

.surveyFormWrapper {
  @extend %form-boundaries;

  > div {
    background-color: colors.$white-color;
    border: 1px solid colors.$light-gray-color;
    padding: 24px 24px 32px;

    &:first-of-type {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding-bottom: 24px;
    }

    &:last-of-type {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:not(:last-of-type) {
      border-bottom: 0;
    }
  }
}

.sectionGrid {
  display: grid;
  grid-template-columns: 284px 1fr;
}

.sectionTitle {
  font-size: 16px;
  font-weight: 600;
}

.dropdown {
  @extend %form-control;

  margin-top: 8px;
  width: 100%;
}

.radioButtonsWrapper {
  display: grid;
  row-gap: 15px;
}

.cadenceWrapper {
  margin-top: 25.5px;
}

.titlesGrid {
  display: grid;
  row-gap: 24px;
}

.titleForDisplay {
  @extend %form-control;

  margin-top: 8px;
  width: 100%;
}

.internalTitlePattern {
  @extend %form-control;

  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  color: colors.$info-color;
  margin-top: 8px;
  width: 66%;
}

.internalTitleSuffix {
  @extend %form-control;

  border-bottom-left-radius: 0;
  border-left: 0;
  border-top-left-radius: 0;
  margin-top: 8px;
  width: 34%;
}

.surveyTypeContainer {
  align-items: center;
  display: flex;
  gap: 12px;
}
