@use '~styles/colors';
@use '~styles/common';

.content {
  height: calc(100vh - common.$generic-header-height);
  padding: 28px 26px 60px;

  > .surveysListWrapper {
    border: 1px solid var(--light-gray);
    box-shadow: none;
    max-height: 100%;
    overflow-y: scroll;

    // Override some styles from the Card.Wrapper component.
    padding: 0;
  }
}
