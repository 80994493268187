@use '~styles/colors';

.searchBoxWrapper {
  position: relative;

  .searchBox {
    background-color: colors.$blue-10-color;
    border-radius: 100px;
    border-width: 0;
    color: colors.$raisin-black-color;
    font-size: 14px;
    height: 36px;
    outline: none;
    padding-left: 36px;
    padding-right: 18px;

    &::placeholder {
      color: colors.$info-color;
    }
  }

  .magnifyingGlassIcon {
    color: colors.$primary-color;
    left: 14px;
    position: absolute;
    top: 12px;
  }
}
