@use '~styles/colors';
@use '~styles/common';

.inputWrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 70px 1fr;
}

.scaleLabelInput {
  @extend %form-control;

  width: 100%;

  &::placeholder {
    color: colors.$info-color;
  }
}

.letterCount {
  color: colors.$info-color;
  display: flex;
  justify-content: end;
  margin-top: 4px;
}
