@use '@zencity/common-ui/lib/zcd-colors.scss';
@use '@zencity/common-ui/lib/zcd-typography';

.sentimentBarWrapper {
  .sentimentBar {
    border-radius: 20px;
    display: flex;
    overflow: hidden;
    width: 358px;

    span {
      display: block;
      height: 6px;
    }
    .positive {
      background-color: zcd-colors.$zcd-color-bg-positive;
    }

    .negative {
      background-color: zcd-colors.$zcd-color-bg-negative;
    }

    .neutral {
      background-color: zcd-colors.$zcd-gray-40;
      margin: 0 2px;
    }
  }
  &.withScoreText {
    .positiveScore {
      @include zcd-typography.t-heading-5;
    }
    .sentimentBar {
      margin-inline-start: 12px;
      width: 160px;
    }
  }
  .isBenchmarked {
    min-width: 97px;
  }
}
