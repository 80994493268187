@use '~styles/colors';
@use '@zencity/common-ui/lib/zcd-colors.scss';

.manageDropdownWrapper {
  position: relative;
}

.actionsDropdown {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  position: absolute;
  right: 0;
  width: 148px;
  z-index: 1;

  .actionItem {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 36px;
    width: 100%;
    &:hover {
      background-color: zcd-colors.$zcd-gray-20;
    }
    p {
      margin-left: 8px;

      // stylelint-disable selector-no-qualifying-type
      &.red {
        color: zcd-colors.$zcd-red-30;
      }
    }
  }
}
