@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

// We must use selector-max-compound-selectors in order to override the ZCDTable styles.
/* stylelint-disable */
$cell-padding-inline: 16px;
$cell-padding-inline-large: 24px;
$cell-padding-block-condesed: 8px;
$table-border: solid 1px zcd-colors.$zcd-gray-40;

.container {
  width: 100%;
}

// We had to add !important to all the styles in this file because the styles
// from the CKEditor override the ZCDTable styles.
// See https://zencity.atlassian.net/browse/PLT-754
// This will be fixed by the PLT team in the future.
.genericTableWrapper {
  table {
    border: unset !important;
    border-collapse: separate !important;
    border-spacing: unset !important;
    width: 100% !important;

    thead th {
      background: zcd-colors.$zcd-blue-20 !important;
      border: unset !important;
      border-bottom: solid 1px zcd-colors.$zcd-blue-30 !important;
      border-top: $table-border !important;
      font-weight: 400 !important;
      min-width: unset !important;
      padding: 13px $cell-padding-inline !important;
      text-align: start !important;
      &:first-child {
        border-inline-start: $table-border !important;
        border-start-start-radius: 8px;
        padding-inline-start: $cell-padding-inline-large !important;
      }
      &:last-child {
        border-inline-end: $table-border !important;
        border-start-end-radius: 8px;
        padding-inline-end: $cell-padding-inline-large !important;
      }
    }
    tbody {
      tr td {
        @include zcd-typography.t-body;

        background: unset !important;
        color: unset;
        border: unset !important;
        border-bottom: $table-border !important;
        min-width: 70px !important;
        padding: $cell-padding-inline !important;
        &:first-child {
          border-inline-start: $table-border !important;
          padding-inline-start: $cell-padding-inline-large !important;
        }
        &:last-child {
          border-inline-end: $table-border !important;
          padding-inline-end: $cell-padding-inline-large !important;
        }
      }
    }
  }
  &.hideTableBorder {
    tr td {
      border-bottom: unset !important;
    }
    tr:last-child td {
      border-bottom: $table-border !important;
    }
  }
  &.hasFooter {
    tr:last-child td {
      border-bottom: unset !important;
    }
    tfoot {
      tr:last-child td {
        border: unset !important;
        border-bottom: $table-border !important;
      }
      tr:last-child td:last-child {
        border-inline-end: $table-border !important;
      }
      tr:last-child td:first-child {
        border-inline-start: $table-border !important;
      }
    }
  }
  &.condensed {
    tbody tr td {
      padding: $cell-padding-block-condesed $cell-padding-inline !important;
    }
    thead th {
      padding: $cell-padding-block-condesed $cell-padding-inline !important;
    }
  }
}
