@use '~styles/colors';

.genericQuestionTeaser {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .text {
    font-weight: 700;
  }

  .additionalInfoToggle {
    cursor: pointer;
  }

  .additionalInfo {
    color: colors.$dove-gray-color;
    margin: 0;
    width: 350px;

    > button {
      color: colors.$dove-gray-color;
      text-decoration: underline;
    }

    > .configsList {
      margin: 0;
      padding-inline-start: 12px;
      // stylelint-disable-next-line selector-max-compound-selectors
      .configListItem {
        list-style-type: '- ';
      }
    }

    > .description {
      list-style: none;
      white-space: normal;
    }

    &:not(.inlineDisplay) {
      .teaserItem {
        padding-inline-start: 24px;
      }
    }

    &.inlineDisplay {
      list-style-type: none;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      white-space: nowrap;

      > li {
        display: inline;

        // stylelint-disable-next-line selector-max-compound-selectors
        + li::before {
          content: '/';
          margin: 0 5px;
        }
      }
    }
  }
}
