@use '~styles/colors';
@use '~styles/common';
.container {
  margin: 29px auto;
  padding-top: 50px;
  width: 710px;
  > div {
    background-color: colors.$white-color;
    border: 1px solid colors.$light-gray-color;
    padding: 24px 24px 32px;

    > svg {
      display: none;
    }

    > select {
      padding-right: 16px;
      text-align: center;
      width: 100%;
    }

    &:first-of-type {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding-bottom: 24px;
    }

    &:last-of-type {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:not(:last-of-type) {
      border-bottom: 0;
    }
  }
}

.widgets {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.spinner {
  display: flex;
  justify-content: center;
}
