@use '~styles/fontSizes';
@use '~styles/colors';

.errorScreen {
  align-items: center;
  display: flex;
  flex-direction: column;
  // The height of the main header is 84px.
  height: calc(100vh - 84px);
  justify-content: center;

  > .content {
    text-align: center;

    > h2 {
      font-size: fontSizes.$font-large;
      margin: 0 0 8px;
    }

    > .description {
      color: colors.$info-color;
      font-size: fontSizes.$font-regular;
      max-width: 483px;
    }
  }

  > .icon {
    margin-bottom: 44px;
  }
}
