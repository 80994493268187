@use '@zencity/common-ui/lib/zcd-colors.scss';
@use '@zencity/common-ui/lib/zcd-typography.scss';

.scoreIndicator {
  align-items: center;
  display: flex;

  .scoreColor {
    border-radius: 50%;
    display: block;
    height: 8px;
    margin-inline-end: 4px;
    width: 8px;

    &.positive {
      background-color: zcd-colors.$zcd-color-positive;
    }
    &.neutral {
      background-color: zcd-colors.$zcd-gray-50;
    }
    &.negative {
      background-color: zcd-colors.$zcd-color-bg-negative;
    }
  }
}
