@use '~styles/colors';
@use '~styles/fontSizes';

.tooltip {
  background-color: colors.$raisin-black-color;
  border-radius: 8px;
  color: colors.$white-color;
  display: flex;
  flex-direction: column;
  font-size: fontSizes.$font-small;
  justify-content: center;
  max-width: 186px;
  padding: 16px;
  position: fixed;
  z-index: 1;

  &::after {
    // The default arrow is pointing down (for the top position).
    border-color: colors.$raisin-black-color transparent transparent transparent;
    border-style: solid;
    border-width: 5px;
    content: ' ';
    position: absolute;
  }

  &.top::after {
    left: 50%;
    margin-left: -5px;
    top: 100%;
  }

  &.right::after {
    margin-top: -5px;
    right: 100%;
    top: 50%;
    transform: rotate(90deg);
  }
}
