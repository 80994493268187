@use '~styles/colors';

.newSurveyLink {
  background-color: colors.$primary-color;
  border-radius: 60px;
  color: colors.$white-color;
  height: 36px;
  line-height: 24px;
  padding: 6px 24px;
  text-decoration: none;
}
