@use '@zencity/common-ui/lib/zcd-colors.scss';

/* stylelint-disable */
.bubbleSurveysWidgetGenerator {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  min-height: 300px;

  .circleCont {
    display: flex;
    align-items: center;
    justify-content: center;

    .circle {
      background: zcd-colors.$zcd-gray-50;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      p {
        color: zcd-colors.$zcd-gray-10;
        line-height: 1.5;

        &:nth-of-type(1) {
          font-size: 20px;
          font-weight: 700;
          margin: 0;
        }

        &:nth-of-type(2) {
          font-weight: 400;
          margin: 0;
          font-size: 16px;
        }
      }
      .innerCircle {
        padding: 0 20px;
      }

      &.positive {
        background: zcd-colors.$zcd-color-positive;
      }

      &.negative {
        background: zcd-colors.$zcd-color-negative;
      }

      &.smallCircle {
        display: block;
        position: relative;
        .innerCircle {
          bottom: calc(100% + 20px);
          position: absolute;
          left: 6px;
          padding-left: 8px;
          padding-right: 0;
          p {
            color: zcd-colors.$zcd-color-text-primary;
            font-size: 15px !important;
            text-align: left;
          }
          &:before {
            content: '';
            display: block;
            width: 0px;
            height: 90px;
            border: 1px dashed zcd-colors.$zcd-gray-30;
            position: absolute;
            bottom: -50px;
            left: 0;
          }
        }
        &.positive .innerCircle:before {
          border-color: zcd-colors.$zcd-color-positive;
        }

        &.negative .innerCircle:before {
          border-color: zcd-colors.$zcd-color-negative;
        }
      }

      &.mediumCircle p {
        font-size: 18px;
        p:nth-of-type(2) {
          font-size: 16px;
        }
      }
    }
    &:nth-of-type(3) {
      margin-right: 54px;
    }
    &:nth-of-type(2) .circle.smallCircle .innerCircle,
    &:nth-of-type(5) .circle.smallCircle .innerCircle {
      bottom: calc(100% + 64px);
    }
    &:nth-of-type(2) .circle.smallCircle .innerCircle:before,
    &:nth-of-type(5) .circle.smallCircle .innerCircle:before {
      height: 130px;
      bottom: -90px;
    }
  }
}
