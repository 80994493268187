@use '@zencity/common-ui/lib/zcd-colors';
@use 'node_modules/@zencity/common-ui/lib/zcd-typography';

.widgetConfig {
  display: grid;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;

  .widgetSettings {
    grid-area: 2 / 1 / 3 / 2;
  }
  .widgetData {
    display: flex;
    flex-direction: column;
    grid-area: 2 / 2 / 3 / 4;
    height: 100%;
    justify-content: space-between;
  }

  textarea {
    background: none;
    background-color: zcd-colors.$zcd-gray-10;
    border: 1px solid zcd-colors.$zcd-gray-40;
    border-radius: 8px;
    font-size: 14px;
    height: 340px;
    width: 100%;
  }

  .actionButtons {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .fileNameButton {
    max-width: 150px;
  }

  .buttonsRow {
    align-items: center;
    align-items: baseline;
    display: flex;
  }

  .widgetSettingsContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
}
