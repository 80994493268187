@use '@zencity/common-ui/lib/zcd-colors';

.editQuestionWarning {
  align-items: center;
  background-color: zcd-colors.$zcd-yellow-90;
  border-radius: 8px;
  color: zcd-colors.$zcd-gray-10;
  display: flex;
  flex-direction: row;
  padding-bottom: 13px;
  padding-left: 18px;
  padding-right: 39px;
  padding-top: 13px;
  svg {
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(1deg) brightness(107%) contrast(101%);
  }
  p {
    font-weight: 600;
    margin-left: 10px;
  }
}
