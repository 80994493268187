/* stylelint-disable selector-no-qualifying-type */
@use '~styles/colors';
@use '~styles/fontSizes';

.spinnerWrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.listTable {
  .filtersWrapper {
    background-color: colors.$white-color;
    column-gap: 16px;
    display: flex;
    justify-content: end;
    padding: 13px 25px;
    position: sticky;
    top: 50px;
  }

  table {
    background-color: colors.$white-color;
    border-radius: 8px;
    table-layout: fixed;
    width: 100%;
  }

  th {
    background-color: colors.$lavender-color;
    box-shadow: 0 -1px 0 colors.$lavender-blue-color, 0 1px 0 colors.$lavender-blue-color;
    color: var(--color-dove-gray);
    font-size: fontSizes.$font-xsmall;
    font-weight: 400;
    height: 38px;
    padding: 11.5px 0;
    position: sticky;
    text-align: start;
    text-transform: uppercase;
    top: 112px;

    &:first-child:not(.selectColumn),
    &.selectColumn:nth-child(2) {
      padding-left: 24px;
    }
  }

  tbody tr {
    box-shadow: 0 1px 0 var(--light-gray);
  }

  td {
    font-weight: 400;
    &:first-child,
    &.selectColumn:nth-child(2) {
      padding: 24px;
    }
  }

  th:first-child:not(.selectColumn),
  td:first-child:not(.selectColumn),
  th.selectColumn:nth-child(2) {
    width: 452px;
  }

  .selectColumn:first-child {
    width: 72px;
  }
}
