@use '~styles/colors';
@use '~styles/common';

.questionContextualToolbar {
  align-items: center;
  background-color: colors.$white-color;
  border: 1px solid colors.$light-gray-color;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: common.$question-contextual-toolbar-height;
  justify-content: space-between;
  left: 100%;
  padding: 12px;
  position: absolute;
  top: 0;
  transition: display 0.3s ease-in 0s;
  width: 48px;
}
