@use '~styles/colors';
@use '~styles/common';

// Add extra styles to the common class `radioOption`.
.radioOption {
  display: flex;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
