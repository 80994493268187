@use '@zencity/common-ui/lib/zcd-colors.scss';
@use '@zencity/common-ui/lib/zcd-typography';

.surveyClientFormContainer {
  padding: 24px;
  width: 710px;
}

.errorMessage {
  @include zcd-typography.t-body;

  color: zcd-colors.$zcd-red-90;
  margin-top: 4px;
}

.successMessage {
  @include zcd-typography.t-body;

  color: zcd-colors.$zcd-green-90;
  margin-top: 4px;
}
