@use '~styles/common';
@use '~styles/fontSizes';
@use '~styles/colors';
@use '@zencity/common-ui/lib/zcd-colors.scss';

.surveyItem {
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  &:hover,
  *:hover {
    cursor: pointer;
  }
  > .content {
    padding-bottom: 32px;
    padding-top: 40px;
    label {
      align-items: center;
      display: flex;
      font-size: fontSizes.$font-large;
      font-weight: 600;
      width: 323px;
    }

    // stylelint-disable selector-no-qualifying-type
    input[type='radio'] {
      margin-left: 0;
      margin-top: 0;
    }

    .surveyStatusText {
      color: colors.$dove-gray-color;
      font-size: fontSizes.$font-regular;
      margin-top: 4px;
    }
  }

  .publishStatusIconContainer {
    display: flex;
    flex-direction: row-reverse;
    flex-grow: 2;

    > .publishStatusIcon {
      border-radius: 50%;
      height: 10px;
      width: 10px;
      &.active {
        background-color: colors.$green-40-color;
      }
      &.unpublishable {
        background-color: colors.$yellow-40-color;
      }
      &.closed {
        background-color: zcd-colors.$zcd-gray-80;
      }
    }
  }
  .shareSurveyButton {
    // TODO: Adjust design once implemented in Figma.
    color: colors.$primary-color;
    cursor: pointer;
    display: flex;
    font-size: fontSizes.$font-large;
    padding: 24px 0;

    svg {
      margin-left: 8px;
    }
  }
}
