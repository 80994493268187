@use '~styles/common';

.scaleQuestion {
  display: flex;
  flex-wrap: wrap;

  .radioOption {
    margin-right: 14px;
    width: 44px;

    // stylelint-disable selector-no-qualifying-type
    input[type='radio'] {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}
