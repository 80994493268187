@use '@zencity/common-ui/lib/zcd-colors';

.barLine {
  border-bottom: 1px solid zcd-colors.$zcd-gray-40;
  min-width: 200px;
  position: relative;
  width: 100%;

  .shape {
    border: 1px solid zcd-colors.$zcd-gray-10;
    height: 13px;
    position: absolute;
    width: 13px;

    &.circle {
      background-color: zcd-colors.$zcd-blue-60;
      border-radius: 50%;
      top: -6px;
      transform: translateX(-50%);
      // Circle is priority 1/3 (highest)
      z-index: 3;
    }

    &.triangle {
      border-color: transparent transparent zcd-colors.$zcd-gray-10 transparent;
      border-style: solid;
      border-width: 0 8px 16px;
      height: 16px;
      transform: translate(-50%, -50%);
      width: 16px;
      // Triangle is priority 2/3
      z-index: 2;
      &::before {
        border-color: transparent transparent zcd-colors.$zcd-gray-80 transparent;
        border-style: solid;
        border-width: 0 8px 16px;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transform: translate(-50%, 0);
        width: 100%;
      }
    }

    &.square {
      background-color: zcd-colors.$zcd-gray-100;
      height: 13px;
      transform: translate(-50%, -50%);
      width: 13px;
      // Square is priority 3/3 (lowest)
      z-index: 1;
    }
  }
}
