@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.sentimentBarRow {
  margin-block-end: 5px;
  .rowText {
    @include zcd-typography.t-body;

    padding-inline-end: 20px;
  }
}
