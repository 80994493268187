@use '~styles/colors';
@use '~styles/common';

.breadcrumbsWrapper {
  background-color: colors.$white-color;
  display: flex;
  font-weight: 600;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.divider {
  margin: 0 8px;
  position: relative;
  width: 24px;

  &::before {
    border-top: 1px solid colors.$light-gray-color;
    content: '';
    position: absolute;
    top: 50%;
    width: inherit;
  }
}
