@use '~styles/common';

.yesNoInputContainer {
  display: flex;
  width: 120px;

  // stylelint-disable selector-no-qualifying-type
  input[type='radio'] {
    margin-right: 8px;
  }
}
