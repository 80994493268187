@use '~styles/colors';
@use '~styles/common';

.breadcrumbLink {
  align-items: center;
  background-color: colors.$gray-20-color;
  border-radius: 60px;
  color: colors.$info-color;
  display: flex;
  height: 36px;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;

  &.active {
    background-color: colors.$blue-20-color;
    color: colors.$primary-color;
  }

  &.disabled {
    pointer-events: none;
  }

  .icon {
    margin-right: 10px;
  }
}
