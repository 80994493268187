@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-colors';

.app {
  padding: 40px;

  .featureTitle {
    @include zcd-typography.t-heading-4;
  }
}
:export {
  negativeColor: zcd-colors.$zcd-color-bg-negative;
  neutralColor: zcd-colors.$zcd-gray-40;
  positiveColor: zcd-colors.$zcd-color-positive;
}
