@use '~styles/colors';
@use '~styles/common';

.toolbar {
  align-items: center;
  background-color: colors.$white-color;
  border-bottom: 1px solid colors.$light-gray-color;
  border-top: 1px solid colors.$light-gray-color;
  display: flex;
  height: common.$toolbar-height;
  position: relative;
}

.backToMainPage {
  color: colors.$dodger-blue-dark-color;
  left: 28px;
  position: absolute;
  text-decoration: none;
}

.nextButton {
  background-color: colors.$primary-color;
  border-radius: 60px;
  color: colors.$white-color;
  cursor: pointer;
  height: 32px;
  padding: 7.5px 24px;
  position: absolute;
  right: 28px;

  &:disabled {
    background-color: colors.$light-gray-color;
    color: colors.$dove-gray-color;
    cursor: not-allowed;
  }
}
