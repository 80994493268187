@use '~styles/common';
@use '~styles/fontSizes';
@use '~styles/colors';

.translationForm {
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-columns: auto 24px auto;
  grid-template-rows: repeat(3, auto);

  .originLanguageLabel {
    grid-area: 1 / 1 / 2 / 2;
  }

  .originLanguageInput {
    grid-area: 2 / 1 / 3 / 2;
  }

  .originLanguagePreviousText {
    grid-area: 3 / 1 / 4 / 2;
  }

  .arrowsIcon {
    align-self: center;
    grid-area: 2 / 2 / 3 / 3;
    transform: rotate(90deg);
  }

  .translatedLanguageLabel {
    grid-area: 1 / 3 / 2 / 4;
  }

  .translatedLanguageInput {
    grid-area: 2 / 3 / 3 / 4;
  }

  .translatedLanguagePreviousText {
    grid-area: 3 / 3 / 4 / 4;
  }

  .contentInput {
    @extend %form-control;

    height: 80px;
    width: 100%;
  }

  .previousText {
    color: colors.$info-color;
    font-size: fontSizes.$font-small;
    width: 260px;
  }
}
