// stylelint-disable
@use '~styles/colors';
@use '~styles/fonts';
@import '@zencity/common-ui/lib/bundle.css';

body {
  background-color: #f7f7f7;
  color: colors.$raisin-black-color;
  font-family: fonts.$font;
}

button {
  background-color: transparent;
  border: 0;
  padding: 0;
  font-family: fonts.$font;
  color: colors.$raisin-black-color;
  &:focus {
    outline: none;
  }
}

textarea {
  font-family: fonts.$font;
}

table {
  font-family: fonts.$font;
}
