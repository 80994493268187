@use '@zencity/common-ui/lib/zcd-colors.scss';

$blue-10-color: var(--blue-10-color);
$blue-20-color: var(--blue-20-color);
$dove-gray-color: var(--color-dove-gray);
$raisin-black-color: var(--color-nero);
$gray-20-color: var(--gray-20-color);
$info-color: var(--info-color);
$light-gray-color: var(--light-gray);
$primary-color: var(--primary-color);
$white-color: var(--white-color);
$color-gray-border: var(--color-grey-border);
$lavender-color: var(--lavender-color);
$lavender-blue-color: var(--lavender-blue-color);
$dodger-blue-dark-color: var(--color-dodger-blue-dark);
$quartz-color: var(--quartz-color);
$blueberry-color: var(--blueberry-color);
$green-40-color: var(--green-40-color);
$yellow-40-color: var(--yellow-40-color);
$red-required-color: var(--red-required-color);
$red-30-color: var(--red-30-color);

:root {
  --blue-10-color: #f5f7fc;
  --blue-20-color: #e8e9ff;
  --gray-20-color: #f7f7f7;
  --info-color: #6d6d6d;
  --white-color: #fff;
  --lavender-color: #e8e9ff;
  --lavender-blue-color: #c3c8fe;
  --quartz-color: #f5f7fc;
  --blueberry-color: #6681ff;
  --green-40-color: #24d394;
  --yellow-40-color: #f9ab01;
  --red-required-color: #f24822;
  --red-30-color: #ff4c59;
}
