@use '@zencity/common-ui/lib/zcd-colors';
@use '~styles/common';

.endScreenToolbar {
  align-items: center;
  background-color: zcd-colors.$zcd-gray-10;
  border: 1px solid zcd-colors.$zcd-gray-40;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: common.$question-contextual-toolbar-height;
  justify-content: space-between;
  left: 100%;
  margin-left: 12px;
  padding: 12px;
  position: absolute;
  top: 0;
  transition: display 0.3s ease-in 0s;
  width: 48px;
}
