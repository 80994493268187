@use '~styles/colors';

.surveyTranslationFormLayout {
  border-top: 1px solid colors.$light-gray-color;
  overflow-y: scroll;
  padding: 28px 40px;
  width: 100%;

  .surveyPreviewBackLink {
    align-items: center;
    cursor: pointer;
    display: flex;
    .backLink {
      margin-left: 8px;
    }
  }

  h2 {
    margin-top: 4px;
  }
}
