@use '~styles/colors';

.tabsWrapper {
  background-color: colors.$white-color;
  position: sticky;
  top: 0;
  z-index: 1;
}

.selectedTabArea {
  background-color: colors.$gray-20-color;
}

.cancelButton {
  margin-right: 40px;
}

.marginAddText {
  margin-left: 12px;
}

:export {
  whiteColor: colors.$white-color;
}
