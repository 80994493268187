@use '~styles/common';
@use '~styles/colors';

.surveyManageScreen {
  display: flex;
  height: calc(100vh - common.$generic-header-height-with-backlink);
}

.cloneButtonLink {
  align-items: center;
  border: 1px solid colors.$dodger-blue-dark-color;
  border-radius: 100px;
  color: colors.$dodger-blue-dark-color;
  display: flex;
  height: 36px;
  padding: 0 24px 0 16px;
  text-decoration: none;
  width: 102px;
  svg {
    margin-right: 8px;
  }
}
