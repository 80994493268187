@use '@zencity/common-ui/lib/zcd-colors.scss';
@use '@zencity/common-ui/lib/zcd-typography.scss';

$label-color-white: zcd-colors.$zcd-gray-10;
$label-color-black: zcd-colors.$zcd-gray-100;
// stylelint-disable selector-max-compound-selectors
.barChart {
  background: zcd-colors.$zcd-gray-20;

  .barFill {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    padding: 8px;
    position: relative;
    width: 100%;

    .barFillContainer {
      height: 100%;
      width: 100%;
    }

    .scoreText {
      @include zcd-typography.t-heading-2;

      color: $label-color-white;
      .diffIndicatorWrapper {
        @include zcd-typography.t-heading-3;

        color: $label-color-white;
      }
    }
    .additionalScores {
      bottom: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      .scoreText {
        @include zcd-typography.t-tag;

        padding: 0 8px;
      }
    }
    &.lowBar {
      padding: 0;
      .barLabel * {
        color: $label-color-black;
      }
      .scoreText {
        @include zcd-typography.t-tag;
      }
      .diffIndicatorWrapper {
        @include zcd-typography.t-tag;
      }

      .barFillContainer {
        bottom: 100%;
        height: auto;
        left: 0;
        padding: 0 8px 8px;
        position: absolute;
        width: 100%;
      }
    }

    .barLabel {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    .scoreDescription {
      @include zcd-typography.t-tag;

      color: $label-color-white;
    }
    .blackLabelColor {
      .scoreText {
        color: $label-color-black;
        .diffIndicatorWrapper {
          color: $label-color-black;
        }
      }
      .scoreDescription {
        color: $label-color-black;
      }
    }
    &.highBar {
      .additionalScores {
        bottom: auto;
        top: 48px;
        .scoreText {
          @include zcd-typography.t-tag;

          color: zcd-colors.$zcd-gray-10;
          .diffIndicatorWrapper {
            color: zcd-colors.$zcd-gray-10;
          }
        }
      }
    }
  }
}
