@use '~styles/common';
@use '~styles/colors';
@use '~styles/fontSizes';

.surveyManageList {
  background-color: colors.$white-color;
  border: 1px solid colors.$light-gray-color;
  height: 100%;
  min-width: 373px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 40px;

  .typeformSurveysList {
    margin-bottom: 32px;
  }
}
