@use '~styles/colors';

.indicationWrapper {
  position: absolute;
  width: 100%;
  z-index: 1;

  .indication {
    align-items: center;
    background-color: colors.$white-color;
    border: 1px solid colors.$light-gray-color;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 0;
    display: flex;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
    flex-direction: column;
    margin: 0 auto;
    padding: 4px;
    width: 200px;
  }
}
