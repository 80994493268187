@use '~styles/colors';

.actionButton {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 32px;
  justify-content: center;
  transition: 0.1s ease-in 0s;
  width: 32px;

  &:hover {
    background-color: colors.$quartz-color;
    color: colors.$dodger-blue-dark-color;
    cursor: pointer;
  }

  .iconContainer {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.active {
  color: colors.$dodger-blue-dark-color;
}
