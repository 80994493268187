@use 'node_modules/@zencity/common-ui/lib/zcd-colors';
@use 'node_modules/@zencity/common-ui/lib/zcd-typography';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

.canvasWrapper {
  align-items: center;
  border: 1px solid zcd-colors.$zcd-gray-40;
  border-radius: 10px;
  border-style: double;
  display: flex;
  font-family: 'Roboto', sans-serif;
  justify-content: center;
  padding: 20px 0;

  .canvaselm {
    display: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    z-index: 2;
  }

  .elmCapture {
    overflow: hidden;
    padding: 0;
    position: relative;
  }
}
