@use '~styles/fontSizes';
@use '~styles/colors';
@use '~styles/common';

.emptyBuildState {
  align-items: center;
  display: flex;
  flex-direction: column;
  // The height is the screen height - the header height - the toolbar height.
  height: calc(100vh - common.$generic-header-height - common.$toolbar-height);
  justify-content: center;

  > .content {
    text-align: center;

    > h2 {
      font-size: fontSizes.$font-xlarge;
      margin: 0 0 8px;
    }

    > .description {
      color: colors.$info-color;
      font-size: fontSizes.$font-regular;
      margin-bottom: 24px;
      max-width: 483px;
    }
  }
}
