@use '~styles/common';

.surveyBuildScreen {
  height: calc(100vh - common.$generic-header-height - common.$toolbar-height);
  overflow-y: scroll;
}

.lockedSurvey {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
