@use '~styles/colors';

@keyframes pulse {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.skeleton {
  animation: pulse 0.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background-color: colors.$light-gray-color;
  height: 10px;
  width: 90%;
}
