@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-colors';

.title {
  @include zcd-typography.t-heading-2;

  margin-block-end: 2px;
}

.subtitle {
  @include zcd-typography.t-body;

  color: zcd-colors.$zcd-gray-60;
  line-height: 24px;
  margin-block-end: 20px;
  max-width: 350px;
}
