@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.container {
  width: 100%;

  h1 {
    @include zcd-typography.t-heading-1;
  }

  .scoresContainer {
    display: flex;
    margin-block-start: 24px;

    .score {
      margin-inline-end: 48px;
    }
    .scoreText {
      @include zcd-typography.t-heading-2;
    }

    .scoreName {
      @include zcd-typography.t-small;

      color: zcd-colors.$zcd-gray-80;
    }
  }
}
