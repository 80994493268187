@use '@zencity/common-ui/lib/zcd-colors.scss';

.satisfactionBarChartCont {
  align-items: flex-end;
  display: flex;
  gap: 4px;
  margin-block-end: 30px;

  &:last-child {
    margin-block-end: 0;
  }
}
