@use '~styles/colors';
@use '~styles/fontSizes';

.surveysListTable {
  height: inherit;
  min-height: 50vh;

  table {
    background-color: colors.$white-color;
    border-radius: 8px;
    table-layout: fixed;
    width: 100%;
  }

  th {
    background-color: colors.$lavender-color;
    box-shadow: 0 -1px 0 colors.$lavender-blue-color, 0 1px 0 colors.$lavender-blue-color;
    color: var(--color-dove-gray);
    font-size: fontSizes.$font-xsmall;
    font-weight: 400;
    height: 38px;
    position: sticky;
    text-align: start;
    text-transform: uppercase;
    top: 62px;
  }

  tbody tr {
    border-bottom: 1px solid colors.$light-gray-color;
    border-top: 1px solid colors.$light-gray-color;
    height: 69px;
  }

  td {
    font-weight: 400;
    &:first-child {
      font-weight: 700;
    }
  }

  th:first-child,
  td:first-child {
    padding-left: 24px;
    width: 428px;
  }

  .row {
    cursor: pointer;
    transition: background-color 0.3s ease-out 0s;
    &:hover {
      background-color: colors.$quartz-color;
    }
  }

  .spinnerWrapper {
    display: flex;
    height: 69px;
    justify-content: center;
    > button {
      align-self: center;
    }
  }

  .filtersWrapper {
    background-color: colors.$white-color;
    column-gap: 16px;
    display: flex;
    justify-content: end;
    padding: 13px 25px;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
