@use '@zencity/common-ui/lib/zcd-colors.scss';
@use '@zencity/common-ui/lib/zcd-typography';

.sentimentBarGroups {
  .sentimentBarGroup {
    margin-block-end: 24px;
    &:last-child {
      margin-block-end: 0;
    }
  }
  h3 {
    @include zcd-typography.t-mini;

    color: zcd-colors.$zcd-gray-80;
    letter-spacing: 0.04em;
    margin-block-end: 8px;
    text-transform: uppercase;
  }
}
