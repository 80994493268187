@use '~styles/colors';
@use '~styles/fontSizes';

.indexContainer {
  align-items: center;
  background-color: colors.$blueberry-color;
  border-radius: 4px;
  color: colors.$white-color;
  display: flex;
  font-size: fontSizes.$font-small;
  height: 20px;
  justify-content: center;
  width: 44px;
}
