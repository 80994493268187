@use '~styles/colors';
@use '~styles/fontSizes';

.missingTranslationWrapper {
  color: colors.$info-color;
}

.missingTranslationText {
  color: colors.$red-30-color;
  font-size: fontSizes.$font-regular;
  font-weight: initial;
}
