@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/common';

.container {
  overflow: visible;
  width: 100vw;
}

.endScreenWrapper {
  background-color: zcd-colors.$zcd-gray-10;
  border-left: 1px solid zcd-colors.$zcd-gray-40;
  border-right: 1px solid zcd-colors.$zcd-gray-40;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  // The mininum height is at least the height of the contextual toolbar,
  // to avoid any overflow scroll issues.
  min-height: common.$question-contextual-toolbar-height;
  position: relative;
  width: 688px;

  .topBorder {
    background-color: zcd-colors.$zcd-blue-50;
    height: 4px;
    width: 100%;
  }

  > .content {
    display: flex;
    flex-direction: column;
    margin: 16px 24px 32px;

    > .title {
      @include zcd-typography.t-heading-2;

      margin-bottom: 12px;
    }

    > .description {
      @include zcd-typography.t-body;
    }
  }
}
