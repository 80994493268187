@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-colors';

.scoreTextWithSentimentBar {
  max-width: 358px;

  .positiveScore {
    @include zcd-typography.t-heading-1;

    color: zcd-colors.$zcd-color-positive;
    font-size: 80px;
    line-height: 1.2;
  }

  //stylelint-disable selector-max-compound-selectors
  .scoreText p {
    @include zcd-typography.t-heading-1;
    span {
      color: zcd-colors.$zcd-color-positive;
    }
  }
}

.sentimentBarOvertime {
  margin-block-start: 12px;
  .overtimeScore {
    @include zcd-typography.t-heading-3;

    margin-block-end: 8px;
    margin-inline-start: -6px;
    p {
      @include zcd-typography.t-body;

      font-weight: 600;
    }
  }
  .overtimeText {
    @include zcd-typography.t-small;

    margin-inline-start: 8px;
  }
}
