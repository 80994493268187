@use '~styles/colors';

.languageSelect {
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;

  .selectBox {
    width: 268px;
  }

  .addButton {
    color: colors.$dodger-blue-dark-color;
  }
}
