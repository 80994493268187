@use '@zencity/common-ui/lib/zcd-colors';
@use '~styles/common';

.newScreenForm {
  background-color: zcd-colors.$zcd-gray-10;
  display: flex;
  gap: 20px;
  padding: 20px 24px;
}
.input {
  width: 50%;

  .screenDescription {
    @extend %form-control;

    margin-top: 12px;
    min-height: 80px;
    resize: vertical;
    width: 100%;
  }
}

.screenType {
  width: 30%;
}

.footer {
  display: flex;
  gap: 24px;
  justify-content: flex-end;
}
