@use '~styles/common';
@use '~styles/colors';
@use '~styles/fontSizes';

.dropdownQuestionContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .description {
    color: colors.$info-color;
    font-size: fontSizes.$font-small;
    font-style: italic;
    margin: 4px 0;
  }
}
