@use '~styles/colors';

.buildForm {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 34px;
  overflow-x: hidden;
  padding-bottom: 5px;

  // The first question wrapper (which is the first child of its container)
  // will have a top border with radius (including its splitter border), and the
  // last child will have a bottom border with radius.

  // stylelint-disable no-descending-specificity
  > :last-child {
    > :first-child {
      border-bottom: 1px solid colors.$light-gray-color;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  //stylelint-disable selector-max-compound-selectors
  > :first-child {
    > :first-child {
      border-top: 1px solid colors.$light-gray-color;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      > :first-child {
        border-radius: inherit;
      }
    }
  }
}

.container {
  display: flex;
}

.endScreensBuilder {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;

  > :last-child {
    > :first-child {
      border-bottom: 1px solid colors.$light-gray-color;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  > :first-child {
    > :first-child {
      border-top: 1px solid colors.$light-gray-color;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      > :first-child {
        border-radius: inherit;
      }
    }
  }
}
