@use '~styles/colors';

$generic-header-height: 84px;
$generic-header-height-with-backlink: 112px;
$toolbar-height: 54px;
$question-contextual-toolbar-height: 133px;

%form-control {
  border: 1px solid colors.$light-gray-color;
  border-radius: 4px;
  height: 36px;
  padding: 7.5px 16px;
}

%squared-dropdown {
  @extend %form-control;

  background-color: colors.$white-color;
}

.reactSortableGhostClass {
  opacity: 0;
}

.reactSortableChosenClass {
  cursor: grabbing;
}

.radioOption {
  width: 350px;

  %input-choice-class {
    height: 16px;
    margin-left: 4px;
    margin-right: 12px;
    width: 16px;
  }

  // stylelint-disable selector-no-qualifying-type
  input[type='radio'] {
    @extend %input-choice-class;

    vertical-align: text-bottom;
  }

  input[type='checkbox'] {
    @extend %input-choice-class;

    vertical-align: bottom;
  }
}

.questionViewEditGrid {
  display: grid;
  grid-template-columns: 60px 1fr;
}
