@use '~styles/colors';
@use '~styles/common';

.stepsDropdownWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stepsDropdown {
  @extend %squared-dropdown;

  width: 72px;
}

.numberRangeWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: lowercase;
}

.rangeInput {
  @extend %squared-dropdown;

  width: 77px;
}

.minMaxSelectionDropdown {
  margin-bottom: 8px;
}
