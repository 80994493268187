@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-colors';

.choicesQuestionChart {
  overflow: visible;

  .questionItem {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }

  .responseOption {
    display: flex;
    flex-direction: column;
    padding-block: 4px;
  }

  .questionTitleContainer {
    display: flex;
    justify-content: flex-end;
    width: 212px;

    // To preserve -webkit compatibility with the stylelint pre-commit hook, we disable the styling here.
    /* stylelint-disable */
    .questionTitle {
      @include zcd-typography.t-tag;

      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      text-align: end;
      min-height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    /* stylelint-enable */
  }

  .barContainer {
    align-items: center;
    display: flex;
  }

  .questionItemBar {
    border-radius: 2px;
    margin: 0 14px;
  }

  .segmentsRoot {
    align-items: center;
    display: flex;
  }

  .segment {
    @include zcd-typography.t-heading-5;

    align-items: center;
    display: flex;
    margin: 0 8px;

    .segmentColor {
      border-radius: 4px;
      height: 16px;
      margin: 0 4px;
      width: 16px;
    }
  }

  .questionItemCount {
    .count {
      @include zcd-typography.t-heading-4;

      line-height: 24px;
      margin-inline-end: 8px;
    }
    .diffWrapper {
      color: zcd-colors.$zcd-color-text-auxiliary;
    }
  }

  .blue {
    background-color: zcd-colors.$zcd-blue-40;
  }

  .red {
    background-color: zcd-colors.$zcd-red-30;
  }

  .purple {
    background-color: zcd-colors.$zcd-purple-90;
  }

  .green {
    background-color: zcd-colors.$zcd-green-40;
  }
}
