@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-colors';

.mainTitle {
  @include zcd-typography.t-action-medium;
}

.subtitle {
  @include zcd-typography.t-action-medium;

  color: zcd-colors.$zcd-gray-70;
}
